import React, { Component } from "react";
import axios from "axios";
import { getServerIp } from "../../services/proxy";

class ContactPresentation extends Component {
  state = { info: [], isLoaded: false };

  componentDidMount() {
    axios
      .get(`${getServerIp()}/wp-json/wp/v2/contact_info/`)
      .then(res => this.setState({ info: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  render() {
    const { info, isLoaded } = this.state;
    if (isLoaded) {
      return (
        <React.Fragment>
          {info.map(info => (
            <div className="presentation-wrapper fade-in" key={info.id}>
              <p
                className="presentation-paragraph"
                dangerouslySetInnerHTML={{ __html: info.acf.text }}
              ></p>
              <p style={{ textAlign: "center" }}>
                Är du intresserad av att jobba hos Drakryggen?{" "}
                <span
                  className="contact-link"
                  onClick={this.props.scrollToElement}
                >
                  Klicka här
                </span>
              </p>
            </div>
          ))}
        </React.Fragment>
      );
    }
    return (
      <div className="loader-wrapper">
        <div className="loader"></div>
      </div>
    );
  }
}

export default ContactPresentation;
