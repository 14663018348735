import React, { Component } from "react";
import axios from "axios";
import EmployeeCardGbg from "./EmployeeCardGbg";
import { getServerIp } from "../../services/proxy";
import { getPostLimitQuery } from "../../services/suffixQuery";

class EmployeeCardsGbg extends Component {
  state = { contactCards: [], isLoaded: false };

  componentDidMount() {
    axios
      .get(
        `${getServerIp()}/wp-json/wp/v2/contact_cards_gbg${getPostLimitQuery()}`
      )
      .then(res => this.setState({ contactCards: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  sortItems = (a, b) => {
    const itemA = a.acf.name.toUpperCase();
    const itemB = b.acf.name.toUpperCase();

    let comparison = 0;
    if (itemA > itemB) {
      comparison = 1;
    } else if (itemA < itemB) {
      comparison = -1;
    }
    return comparison;
  };

  render() {
    const { contactCards, isLoaded } = this.state;
    const secondColumnStart = Math.floor(contactCards.length / 2);
    if (isLoaded) {
      return (
        <React.Fragment>
          <div className="employee-cards-wrapper fade-in">
            {contactCards
              .slice(secondColumnStart)
              .sort(this.sortItems)
              .map(card => (
                <EmployeeCardGbg card={card} key={card.id} />
              ))}
          </div>
          <div className="employee-cards-wrapper fade-in">
            {contactCards
              .slice(0, secondColumnStart)
              .sort(this.sortItems)
              .map(card => (
                <EmployeeCardGbg card={card} key={card.id} />
              ))}
          </div>
        </React.Fragment>
      );
    }
    return (
      <div className="loader-wrapper">
        <div className="loader"></div>
      </div>
    );
  }
}

export default EmployeeCardsGbg;
