import React, { Component } from "react";
import axios from "axios";
import { getServerIp } from "../../services/proxy";

class LandingImage extends Component {
  state = { logo: [], isLoaded: false };

  componentDidMount() {
    axios
      .get(`${getServerIp()}/wp-json/wp/v2/logo/`)
      .then(res => this.setState({ logo: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  render() {
    const { logo, isLoaded } = this.state;
    if (isLoaded) {
      return (
        <React.Fragment>
          <div className="landing-image-wrapper">
            {/*Replace H1 with image*/}
            <div className="landning-image-content-wrapper fade-in">
              {logo.map(logo => (
                <React.Fragment>
                  <img
                    className="landing-image-logo"
                    key={logo.id}
                    src={`${getServerIp()}` + logo.acf.logo.url}
                    alt={logo.acf.logo.alt}
                  />
                  <p>{logo.acf.text_on_landing_image}</p>
                </React.Fragment>
              ))}
            </div>
            <div style={{ height: "4em", width: "100%" }}></div>
            <span
              className="scroll-down-arrow"
              onClick={this.props.scrollToElement}
            >
              ▼
            </span>
          </div>
          <div className="photoGuyCred">
            <p>Foto: Tobias Granath</p>
          </div>
        </React.Fragment>
      );
    }
    return (
      <div className="landing-image-wrapper-2">
        {/*Replace H1 with image*/}
        <div className="landning-image-content-wrapper">
          <img
            src="/small-logo.png"
            alt="small-logo"
            className="pulse pulsing-logo"
          ></img>
        </div>
      </div>
    );
  }
}

export default LandingImage;
