import React, { Component } from "react";
import axios from "axios";
import { getServerIp } from "../../services/proxy";

class WeWorkAtInfo extends Component {
  state = { workAtInfo: [], isLoaded: false };

  componentDidMount() {
    axios
      .get(`${getServerIp()}/wp-json/wp/v2/we_work_at/`)
      .then(res => this.setState({ workAtInfo: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  render() {
    const { workAtInfo, isLoaded } = this.state;
    if (isLoaded) {
      return (
        <React.Fragment>
          {/*<details>
            <summary>{workAtInfo.acf.excerpt + "..."}</summary>
            <p>{workAtInfo.acf.text}</p>
          </details>*/}
          {workAtInfo.map(workAtInfo => (
            <p
              key={workAtInfo.id}
              dangerouslySetInnerHTML={{ __html: workAtInfo.acf.text }}
            ></p>
          ))}

          {/*<button className="read-more-button">Läs mer</button>*/}
        </React.Fragment>
      );
    }
    return <div className="loader justified-loader"></div>;
  }
}

export default WeWorkAtInfo;
