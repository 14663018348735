import React, { Component } from "react";
import EmployeeCardsGbg from "../appSpecific/EmployeeCardsGbg";
import EmployeeCardsLinkpg from "../appSpecific/EmployeeCardsLinkpg";
import AboutUsInfo from "../appSpecific/AboutUsInfo";

class AboutUsPage extends Component {
  componentDidMount() {
    this.setAboutUsActive();
  }

  setAboutUsActive = () => {
    this.props.setAboutUsActive();
  };
  render() {
    return (
      <div className="pages-container">
        <h2>Vi är Drakryggen</h2>
        <div className="employee-cards-wrapper-container">
          <EmployeeCardsLinkpg />
        </div>
        {/*<div className="employee-cards-wrapper-container">
          <EmployeeCardsGbg />
    </div>*/}
        <div className="header-wrapper">
          <h2 className="section-two-header">Vår historia</h2>
        </div>
        <AboutUsInfo />
      </div>
    );
  }
}

export default AboutUsPage;
