import React, { Component } from "react";
import WeWorkAtPresentation from "../appSpecific/WeWorkAtPresentation";
import WeWorkAtNews from "../appSpecific/WeWorkAtNews";

class NewsPage extends Component {
  componentDidMount() {
    this.setDragonBlogActive();
  }

  setDragonBlogActive = () => {
    this.props.setDragonBlogActive();
  };
  render() {
    return (
      <div className="pages-container">
        <h2>Drakbloggen</h2>
        <WeWorkAtPresentation />
        <WeWorkAtNews />
      </div>
    );
  }
}

export default NewsPage;
