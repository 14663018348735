import React, { Component } from "react";
import axios from "axios";
import { getServerIp } from "../../services/proxy";
import { getPostLimitQuery } from "../../services/suffixQuery";

class WorkWithUsAreas extends Component {
  state = { areas: [], isLoaded: false };

  componentDidMount() {
    axios
      .get(`${getServerIp()}/wp-json/wp/v2/work_with_us${getPostLimitQuery()}`)
      .then(res => this.setState({ areas: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  sortItem = (a, b) => {
    const itemA = a.acf.label.toUpperCase();
    const itemB = b.acf.label.toUpperCase();

    let comparison = 0;
    if (itemA > itemB) {
      comparison = 1;
    } else if (itemA < itemB) {
      comparison = -1;
    }
    return comparison;
  };

  render() {
    return (
      <div className="work-with-areas-wrapper">
        {this.state.areas.sort(this.sortItem).map(area => {
          if (area.acf.identifier === "dr_employment_label") {
            return (
              <div key={area.id}>
                <div className="work-item-wrapper">
                  <img
                    className="work-icon-image"
                    src={`${getServerIp()}` + area.acf.icon.url}
                    alt={area.acf.icon.alt}
                  />
                  <span className="work-label-2">{area.acf.label}</span>
                </div>
                <button className="click-here-button">
                  <a
                    href="https://join.drakryggen.com/"
                    target="_self"
                    rel="noopener noreferrer"
                  >
                    Lediga tjänster
                  </a>
                </button>
              </div>
            );
          } else if (area.acf.identifier === "dr_idea_label") {
            return null;
            /*<div key={area.id}>
                <div className="work-item-wrapper">
                  <img
                    className="work-icon-image"
                    src={area.acf.icon.url}
                    alt={area.acf.icon.alt}
                  />
                  <span className="work-label-2">{area.acf.label}</span>
                </div>
              </div>*/
          }
          return area;
        })}
      </div>
    );
  }
}

export default WorkWithUsAreas;
