import React, { Component } from "react";
import axios from "axios";
import { getServerIp } from "../../services/proxy";

class AboutUsInfo extends Component {
  state = { info: [], isLoaded: false };

  componentDidMount() {
    axios
      .get(`${getServerIp()}/wp-json/wp/v2/about_us/`)
      .then(res => this.setState({ info: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  render() {
    const { info, isLoaded } = this.state;
    if (isLoaded) {
      return (
        <React.Fragment>
          {info.map(info => (
            <div className="presentation-wrapper fade-in" key={info.id}>
              <p
                className="presentation-paragraph-2"
                dangerouslySetInnerHTML={{ __html: info.acf.info }}
              ></p>
            </div>
          ))}
        </React.Fragment>
      );
    }
    return (
      <div className="loader-wrapper">
        <div className="loader"></div>
      </div>
    );
  }
}

export default AboutUsInfo;
