import React, { Component } from "react";

import WeWorkLanding from "../appSpecific/WeWorkLanding";
import WeWorkAtLanding from "../appSpecific/WeWorkAtLanding";

class PageSectionWork extends Component {
  state = {};
  render() {
    return (
      <div className="page-sect-work-wrapper">
        <WeWorkLanding weKnowActive={this.props.weKnowActive} />
        <WeWorkAtLanding />
      </div>
    );
  }
}

export default PageSectionWork;
