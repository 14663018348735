import React, { Component } from "react";
import AnimatedShowMore from "react-animated-show-more";
import { getServerIp } from "../../services/proxy";

class EmployeeCardGbg extends Component {
  render() {
    const { card } = this.props;

    return (
      <React.Fragment>
        <div className="employee-super-container">
          <div className="employee-card-wrapper">
            <img
              className="contact-icon-image"
              src={`${getServerIp()}` + card.acf.icon.url}
              alt={card.acf.icon.alt}
            />
            <div className="contact-card-text-content-wrapper">
              <p className="contact-card-name">{card.acf.name}</p>
              <p className="contact-card-title">{card.acf.title}</p>
              <p className="contact-card-email">
                <span className="fa-icon">
                  <i className="fa fa-envelope-o" aria-hidden="true"></i>
                </span>{" "}
                <a href={"mailto:" + card.acf.email}>{card.acf.email}</a>
              </p>
              <p className="contact-card-phone">
                <span className="fa-icon">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </span>{" "}
                <a href={"tel:" + card.acf.phone}>+{card.acf.phone}</a>
              </p>
            </div>
          </div>
          <AnimatedShowMore
            height={0}
            toggle={({ isOpen }) => (isOpen ? "▲" : "▼")}
            speed={1000}
            shadowColor="#fff"
            toggleColor="#fff"
          >
            <p
              className="contact-card-presentation"
              dangerouslySetInnerHTML={{ __html: card.acf.presentation }}
            ></p>
          </AnimatedShowMore>
        </div>
      </React.Fragment>
    );
  }
}

export default EmployeeCardGbg;
