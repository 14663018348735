import React, { Component } from "react";
import WeWorkAtInfo from "./WeWorkAtInfo";
//import WorkWithUsInfo from "./WorkWithUsInfo";
import WorkWithUsAreas from "./WorkWithUsAreas";

class WeWorkAtLanding extends Component {
  state = {};
  render() {
    return (
      <div className="we-work-at-wrapper">
        <h2>Jobba med oss</h2>
        <WorkWithUsAreas />
        {/*<WorkWithUsInfo />*/}
        <h2>Vad vi gör</h2>
        <WeWorkAtInfo />
      </div>
    );
  }
}

export default WeWorkAtLanding;
