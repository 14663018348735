import React, { Component } from "react";
import CoreValues from "../appSpecific/CoreValues";

class PageSectionValues extends Component {
  state = {};
  render() {
    return (
      <div className="page-sect-val-wrapper">
        <h2>Vår värdegrund</h2>
        <CoreValues />
      </div>
    );
  }
}

export default PageSectionValues;
