import React, { Component } from "react";
import { getServerIp } from "../../services/proxy";
import { Link } from "react-router-dom";

class WorkAreaItem extends Component {
  render() {
    const { workItem } = this.props;
    if (workItem.acf.display_on_start === true) {
      if (workItem.acf.work_icon !== false) {
        return (
          <React.Fragment>
            <div className="work-item-wrapper">
              <Link to="/work-with">
                <img
                  className="work-icon-image"
                  src={`${getServerIp()}` + workItem.acf.work_icon.url}
                  alt={workItem.acf.work_icon.alt}
                  onClick={this.props.weKnowActive}
                />
              </Link>
              <p className="work-label">{workItem.acf.work_label}</p>
            </div>
          </React.Fragment>
        );
      }
      return (
        <React.Fragment>
          <div className="work-item-wrapper">
            <Link to="/work-with">
              <p className="work-label">{workItem.acf.work_label}</p>
            </Link>
          </div>
        </React.Fragment>
      );
    }
    return null;
  }
}

export default WorkAreaItem;
