import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Container
} from "reactstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { getServerIp } from "../../services/proxy";

class AppNavbar extends Component {
  state = { logotype: [], isLoaded: false, isOpen: false };

  componentDidMount() {
    axios
      .get(`${getServerIp()}/wp-json/wp/v2/logo/`)
      .then(res => this.setState({ logotype: res.data, isLoaded: true }))
      .catch(err => console.log(err));

    window.addEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ isOpen: this.state.isOpen });
  };

  setHomeFocused() {
    if (window.matchMedia("(max-width: 800px)").matches) {
      return {
        borderLeft:
          this.props.getState[0].isActive === false
            ? "0.2em solid #0c1c27"
            : "0.2em solid red",
        borderBottom:
          this.props.getState[0].isActive === false
            ? "0em solid #0c1c27"
            : "0em solid #0c1c27"
      };
    } else if (window.matchMedia("(min-width: 800px)").matches) {
      return {
        borderBottom:
          this.props.getState[0].isActive === false
            ? "0.2em solid #0c1c27"
            : "0.2em solid red"
      };
    }
  }

  setWeKnowFocused() {
    if (window.matchMedia("(max-width: 800px)").matches) {
      return {
        borderLeft:
          this.props.getState[1].isActive === false
            ? "0.2em solid #0c1c27"
            : "0.2em solid red",
        borderBottom:
          this.props.getState[1].isActive === false
            ? "0em solid #0c1c27"
            : "0em solid #0c1c27"
      };
    } else if (window.matchMedia("(min-width: 800px)").matches) {
      return {
        borderBottom:
          this.props.getState[1].isActive === false
            ? "0.2em solid #0c1c27"
            : "0.2em solid red"
      };
    }
  }

  setDragonBlogFocused() {
    if (window.matchMedia("(max-width: 800px)").matches) {
      return {
        borderLeft:
          this.props.getState[2].isActive === false
            ? "0.2em solid #0c1c27"
            : "0.2em solid red",
        borderBottom:
          this.props.getState[2].isActive === false
            ? "0em solid #0c1c27"
            : "0em solid #0c1c27"
      };
    } else if (window.matchMedia("(min-width: 800px)").matches) {
      return {
        borderBottom:
          this.props.getState[2].isActive === false
            ? "0.2em solid #0c1c27"
            : "0.2em solid red"
      };
    }
  }

  setAboutUsFocused() {
    if (window.matchMedia("(max-width: 800px)").matches) {
      return {
        borderLeft:
          this.props.getState[3].isActive === false
            ? "0.2em solid #0c1c27"
            : "0.2em solid red",
        borderBottom:
          this.props.getState[3].isActive === false
            ? "0em solid #0c1c27"
            : "0em solid #0c1c27"
      };
    } else if (window.matchMedia("(min-width: 800px)").matches) {
      return {
        borderBottom:
          this.props.getState[3].isActive === false
            ? "0.2em solid #0c1c27"
            : "0.2em solid red"
      };
    }
  }

  setContactUsFocused() {
    if (window.matchMedia("(max-width: 800px)").matches) {
      return {
        borderLeft:
          this.props.getState[4].isActive === false
            ? "0.2em solid #0c1c27"
            : "0.2em solid red",
        borderBottom:
          this.props.getState[4].isActive === false
            ? "0em solid #0c1c27"
            : "0em solid #0c1c27"
      };
    } else if (window.matchMedia("(min-width: 800px)").matches) {
      return {
        borderBottom:
          this.props.getState[4].isActive === false
            ? "0.2em solid #0c1c27"
            : "0.2em solid red"
      };
    }
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { logotype, isLoaded } = this.state;
    const {
      homeActive,
      weKnowActive,
      dragonBlogActive,
      aboutUsActive,
      contactUsActive
    } = this.props;
    if (isLoaded) {
      return (
        <div>
          <Navbar dark expand="sm" className="navbar">
            <Container>
              <Link
                to="/"
                className="text-light logo-text text-decoration-none"
                onClick={() => {
                  if (window.matchMedia("(max-width: 800px)").matches) {
                    this.setState({ isOpen: false });
                  }
                  homeActive();
                }}
              >
                {logotype.map(logotype => (
                  <img
                    className="logo-image fade-in"
                    key={logotype.id}
                    src={`${getServerIp()}` + logotype.acf.logo.url}
                    alt={logotype.acf.logo.alt}
                    onClick={() => {
                      if (window.matchMedia("(max-width: 800px)").matches) {
                        this.setState({ isOpen: false });
                      }
                      homeActive();
                    }}
                  />
                ))}
              </Link>
              <NavbarToggler onClick={this.toggle} />
              <Collapse isOpen={this.state.isOpen} navbar>
                <Nav className="ml-auto">
                  <NavItem className="home-link">
                    <Link
                      className="nav-link"
                      to="/"
                      onClick={() => {
                        if (window.matchMedia("(max-width: 800px)").matches) {
                          this.toggle();
                        }
                        homeActive();
                      }}
                      style={this.setHomeFocused()}
                    >
                      Hem
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      to="/work-with"
                      onClick={() => {
                        if (window.matchMedia("(max-width: 800px)").matches) {
                          this.toggle();
                        }
                        weKnowActive();
                      }}
                      style={this.setWeKnowFocused()}
                    >
                      Vad vi kan
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      to="/news"
                      onClick={() => {
                        if (window.matchMedia("(max-width: 800px)").matches) {
                          this.toggle();
                        }
                        dragonBlogActive();
                      }}
                      style={this.setDragonBlogFocused()}
                    >
                      Drakbloggen
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      to="/about"
                      onClick={() => {
                        if (window.matchMedia("(max-width: 800px)").matches) {
                          this.toggle();
                        }
                        aboutUsActive();
                      }}
                      style={this.setAboutUsFocused()}
                    >
                      Om oss
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link
                      className="nav-link"
                      to="/contact"
                      onClick={() => {
                        if (window.matchMedia("(max-width: 800px)").matches) {
                          this.toggle();
                        }
                        contactUsActive();
                      }}
                      style={this.setContactUsFocused()}
                    >
                      Kontakta oss
                    </Link>
                  </NavItem>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </div>
      );
    }
    return (
      <div>
        <Navbar dark expand="sm" className="navbar-2">
          <Container>
            <Link
              to="/"
              className="text-light logo-text text-decoration-none"
            ></Link>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto">
                <NavItem>
                  <Link
                    className="text-light text-decoration-none mr-3"
                    to="/"
                  ></Link>
                </NavItem>
                <NavItem>
                  <Link className="nav-link" to="/news"></Link>
                </NavItem>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default AppNavbar;
