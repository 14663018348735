import React, { Component } from "react";

class CoreValueItem extends Component {
  render() {
    const { value } = this.props;
    return (
      <React.Fragment>
        <div className="value-container">
          <h3>{value.acf.title}</h3>
          <p dangerouslySetInnerHTML={{ __html: value.acf.text }}></p>
        </div>
      </React.Fragment>
    );
  }
}

export default CoreValueItem;
