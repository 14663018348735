import React, { Component } from "react";
import axios from "axios";
import CoreValueItem from "./CoreValueItem";
import { getServerIp } from "../../services/proxy";

class CoreValues extends Component {
  state = { values: [], isLoaded: false };

  componentDidMount() {
    axios
      .get(`${getServerIp()}/wp-json/wp/v2/values`)
      .then(res => this.setState({ values: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  render() {
    const { values, isLoaded } = this.state;
    if (isLoaded) {
      return (
        <div className="core-values-wrapper">
          {values.map(value => (
            <CoreValueItem value={value} key={value.id} />
          ))}
        </div>
      );
    }
    return <div className="loader justified-loader"></div>;
  }
}

export default CoreValues;
