import React, { Component } from "react";
import axios from "axios";

import WeWorkWithItem from "./WeWorkWithItem";
import { getServerIp } from "../../services/proxy";
import { getPostLimitQuery } from "../../services/suffixQuery";

class WeWorkWith extends Component {
  state = { workAreas: [], isLoaded: false };

  componentDidMount() {
    axios
      .get(`${getServerIp()}/wp-json/wp/v2/we_work_with${getPostLimitQuery()}`)
      .then(res => this.setState({ workAreas: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  sortItems = (a, b) => {
    const itemA = a.acf.work_label.toUpperCase();
    const itemB = b.acf.work_label.toUpperCase();

    let comparison = 0;
    if (itemA > itemB) {
      comparison = 1;
    } else if (itemA < itemB) {
      comparison = -1;
    }
    return comparison;
  };

  render() {
    const { workAreas, isLoaded } = this.state;
    const secondColumnStart = Math.floor(workAreas.length / 2);
    if (isLoaded) {
      return (
        <div className="work-areas-wrapper fade-in">
          <div className="work-item-container-wrapper">
            {workAreas
              .sort(this.sortItems)
              .slice(0, secondColumnStart)
              .map(workItem => (
                <WeWorkWithItem workItem={workItem} key={workItem.id} />
              ))}
          </div>
          <div className="work-item-container-wrapper">
            {workAreas
              .sort(this.sortItems)
              .slice(secondColumnStart)
              .map(workItem => (
                <WeWorkWithItem workItem={workItem} key={workItem.id} />
              ))}
          </div>
        </div>
      );
    }
    return (
      <div className="loader-wrapper">
        <div className="loader"></div>
      </div>
    );
  }
}

export default WeWorkWith;
