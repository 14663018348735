import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import WorkAreaItem from "./WorkAreaItem";
import { getServerIp } from "../../services/proxy";
import { getPostLimitQuery } from "../../services/suffixQuery";

class WorkAreas extends Component {
  state = {
    works: [],
    isLoaded: false
  };

  componentDidMount() {
    axios
      .get(`${getServerIp()}/wp-json/wp/v2/we_work_with${getPostLimitQuery()}`)
      .then(res => this.setState({ works: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  sortItems = (a, b) => {
    const itemA = a.acf.work_label.toUpperCase();
    const itemB = b.acf.work_label.toUpperCase();

    let comparison = 0;
    if (itemA > itemB) {
      comparison = 1;
    } else if (itemA < itemB) {
      comparison = -1;
    }
    return comparison;
  };

  render() {
    const { works, isLoaded } = this.state;
    if (isLoaded) {
      return (
        <React.Fragment>
          <div className="work-items-wrapper">
            {works.sort(this.sortItems).map(workItem => (
              <WorkAreaItem
                workItem={workItem}
                key={workItem.id}
                weKnowActive={this.props.weKnowActive}
              />
            ))}
          </div>
          <Link to="/work-with">
            <button
              className="read-more-button"
              onClick={this.props.weKnowActive}
            >
              Läs mer
            </button>
          </Link>
        </React.Fragment>
      );
    }
    return <div className="loader justified-loader"></div>;
  }
}

export default WorkAreas;
