import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { getServerIp } from "../../services/proxy";
import { getPostLimitQuery } from "../../services/suffixQuery";

class NewsItemPage extends Component {
  state = { newsItem: {}, isLoaded: false };

  componentDidMount() {
    this.setDragonBlogActive();
    axios
      .get(
        `${getServerIp()}/wp-json/wp/v2/dr_news/${
          this.props.match.params.id
        }${getPostLimitQuery()}`
      )
      .then(res => this.setState({ newsItem: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  setDragonBlogActive = () => {
    this.props.setDragonBlogActive();
  };

  render() {
    const { newsItem, isLoaded } = this.state;
    if (isLoaded && newsItem.acf.text.match(/wp-content/g)) {
      return (
        <div className="pages-container">
          <div className="news-post-container">
            <div className="news-post-wrapper fade-in">
              <Link to="/news">
                <button className="back-button">
                  <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                  Tillbaka
                </button>
              </Link>
              <h2 className="news-title">{newsItem.acf.title}</h2>
              <p className="date-string">
                {newsItem.date.substring(0, newsItem.date.length - 9)}
              </p>
              <div className="author-info-wrapper">
                <img
                  className="author-image"
                  src={`${getServerIp()}` + newsItem.acf.author_image.url}
                  alt={newsItem.acf.author_image.alt}
                />
                <p className="author-name">Av {newsItem.acf.author}</p>
              </div>
              <img
                className="post-page-image"
                src={`${getServerIp()}` + newsItem.acf.post_image.url}
                alt={newsItem.acf.post_image.alt}
              />
              <div
                className="post-info-paragraph"
                dangerouslySetInnerHTML={{
                  __html: newsItem.acf.text.replace(
                    new RegExp("/wp-content/", "g"),
                    getServerIp() + "/wp-content/"
                  )
                }}
              />
            </div>
          </div>
        </div>
      );
    } else if (isLoaded) {
      return (
        <div className="pages-container">
          <div className="news-post-container">
            <div className="news-post-wrapper fade-in">
              <Link to="/news">
                <button className="back-button">
                  <i className="fa fa-caret-left" aria-hidden="true"></i>{" "}
                  Tillbaka
                </button>
              </Link>
              <h2 className="news-title">{newsItem.acf.title}</h2>
              <p className="date-string">
                {newsItem.date.substring(0, newsItem.date.length - 9)}
              </p>
              <div className="author-info-wrapper">
                <img
                  className="author-image"
                  src={`${getServerIp()}` + newsItem.acf.author_image.url}
                  alt={newsItem.acf.author_image.alt}
                />
                <p className="author-name">Av {newsItem.acf.author}</p>
              </div>
              <img
                className="post-page-image"
                src={`${getServerIp()}` + newsItem.acf.post_image.url}
                alt={newsItem.acf.post_image.alt}
              />
              <div
                className="post-info-paragraph"
                dangerouslySetInnerHTML={{
                  __html: newsItem.acf.text
                }}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="loader-wrapper-high">
        <div className="loader"></div>
      </div>
    );
  }
}

export default NewsItemPage;
