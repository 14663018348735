import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getServerIp } from "../../services/proxy";

class WeWorkAtNewsItem extends Component {
  state = {};
  render() {
    const { newsItem, id } = this.props;
    return (
      <div className="news-item fade-in">
        <Link to={`/news/newsItem${id}`} className="text-decoration-none">
          <img
            className="news-item-image"
            src={`${getServerIp()}` + newsItem.acf.post_image.url}
            alt={newsItem.acf.post_image.alt}
          />
          <p>
            {newsItem.title.rendered}
            <br />
            <span>{newsItem.date.substring(0, newsItem.date.length - 9)}</span>
          </p>
        </Link>
      </div>
    );
  }
}

export default WeWorkAtNewsItem;
