import React, { Component } from "react";
import WeWorkWith from "../appSpecific/WeWorkWith";

class WeWorkWithPage extends Component {
  componentDidMount() {
    this.setWeKnowActive();
  }

  setWeKnowActive = () => {
    this.props.setWeKnowActive();
  };
  render() {
    return (
      <div className="we-work-with-page-container">
        <h2>Vad vi kan</h2>
        <WeWorkWith />
      </div>
    );
  }
}

export default WeWorkWithPage;
