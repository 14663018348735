import React, { Component } from "react";
import axios from "axios";
import ContactCardGbg from "./ContactCardGbg";
import { getServerIp } from "../../services/proxy";
import { getPostLimitQuery } from "../../services/suffixQuery";

class ContactCardsGbg extends Component {
  state = { contactCards: [], isLoaded: false };

  componentDidMount() {
    axios
      .get(
        `${getServerIp()}/wp-json/wp/v2/contact_cards_gbg${getPostLimitQuery()}`
      )
      .then(res => this.setState({ contactCards: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  render() {
    const { contactCards, isLoaded } = this.state;
    if (isLoaded) {
      return (
        <React.Fragment>
          <div className="contact-cards-wrapper fade-in">
            {contactCards.reverse().map(card => (
              <ContactCardGbg card={card} key={card.id} />
            ))}
          </div>
        </React.Fragment>
      );
    }
    return (
      <div className="loader-wrapper">
        <div className="loader"></div>
      </div>
    );
  }
}

export default ContactCardsGbg;
