import React, { Component } from "react";
import ContactPresentation from "../appSpecific/ContactPresentation";
import ContactCardsSection from "../sections/ContactCardsSection";
import ContactUsForm from "../appSpecific/ContactUsForm";
import WorkWithUsAreas from "../appSpecific/WorkWithUsAreas";
import scrollToComponent from "react-scroll-to-component";

class ContactPage extends Component {
  componentDidMount() {
    this.setContactUsActive();
  }

  setContactUsActive = () => {
    this.props.setContactUsActive();
  };

  scrollToElement = () => {
    scrollToComponent(this.scrollElement, {
      offset: -60,
      align: "top",
      duration: 1500
    });
  };

  render() {
    return (
      <div className="pages-container">
        <h2>Kontakta oss</h2>
        <ContactPresentation scrollToElement={this.scrollToElement} />
        <ContactCardsSection />
        <div
          className="work-with-us-contact-page-wrapper"
          ref={section => {
            this.scrollElement = section;
          }}
        >
          <h2>Jobba med oss</h2>
          <WorkWithUsAreas />
        </div>
        <ContactUsForm
          formSubmitTo="email"
          submissionEmail="richard.kappling@drakryggen.com"
          containerClassName="container"
        />
      </div>
    );
  }
}

export default ContactPage;
