import React, { Component } from "react";
import ContactCardsLinkpg from "../appSpecific/ContactCardsLinkpg";
import ContactCardsGbg from "../appSpecific/ContactCardsGbg";

class ContactCardsSection extends Component {
  render() {
    return (
      <React.Fragment>
        {/*<div className="contact-offices-wrapper">*/}
        {/*<div className="contact-office">*/}
        {/*<h2>Kontaktpersoner Linköping</h2>*/}
        <ContactCardsLinkpg />
        {/*</div>
        <div className="contact-office">
          <h2>Kontaktperson Göteborg</h2>
          <ContactCardsGbg />
    </div>*/}
        {/*</div>*/}
      </React.Fragment>
    );
  }
}

export default ContactCardsSection;
