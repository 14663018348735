import React, { Component } from "react";
import ReactContactForm from "react-mail-form";

class ContactUsForm extends Component {
  state = {};
  render() {
    return (
      <div className="contact-form-wrapper">
        <h2>Allmän kontakt</h2>
        <ReactContactForm
          to="richard.kappling@drakryggen.com"
          titlePlaceholder="Ämne"
          contentsPlaceholder="Innehåll"
          buttonText="Skicka"
          className="contact-form"
        />
      </div>
    );
  }
}

export default ContactUsForm;
