import React, { Component } from "react";
import scrollToComponent from "react-scroll-to-component";

import LandingImage from "../sections/LandingImage";
import PageSectionWork from "../sections/PageSectionWork";
import PageSectionValues from "../sections/PageSectionValues";
import PageSectionFindUs from "../sections/PageSectionFindUs";

class LandingPage extends Component {
  componentDidMount() {
    this.setHomeActive();
  }

  setHomeActive = () => {
    this.props.setHomeActive();
  };

  scrollToElement = () => {
    scrollToComponent(this.scrollElement, {
      offset: -60,
      align: "top",
      duration: 1500
    });
  };

  render() {
    return (
      <div>
        <LandingImage scrollToElement={this.scrollToElement} />
        <PageSectionWork
          ref={section => {
            this.scrollElement = section;
          }}
          weKnowActive={this.props.weKnowActive}
        />
        <PageSectionValues />
        <PageSectionFindUs />
      </div>
    );
  }
}

export default LandingPage;
