import React, { Component } from "react";
import axios from "axios";

import WeWorkAtNewsItem from "./WeWorkAtNewsItem";
import { getServerIp } from "../../services/proxy";
import { getPostLimitQuery } from "../../services/suffixQuery";

class WeWorkAtNews extends Component {
  state = { news: [], visible: 4, isLoaded: false };

  componentDidMount() {
    axios
      .get(`${getServerIp()}/wp-json/wp/v2/dr_news/${getPostLimitQuery()}`)
      .then(res => this.setState({ news: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  loadMore = () => {
    this.setState(prev => {
      return { visible: prev.visible + 2 };
    });
  };

  render() {
    //console.log(this.state);
    const { news, isLoaded, visible } = this.state;
    if (isLoaded) {
      return (
        <div className="news-container">
          <div className="news-title-container-top">
            <h2>
              Inkast från vår vardag <br />
              <span className="text-danger">
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </span>{" "}
            </h2>
          </div>
          <div className="news-item-container">
            {news.slice(0, visible).map(newsItem => (
              <WeWorkAtNewsItem
                newsItem={newsItem}
                key={newsItem.id}
                id={newsItem.id}
              />
            ))}
            {this.state.visible < this.state.news.length && (
              <div className="button-wrapper-2">
                <button onClick={this.loadMore} className="read-more-button">
                  Ladda fler
                </button>
              </div>
            )}
          </div>
          <div className="news-title-container">
            <h2>
              <span className="text-danger">
                <i className="fa fa-caret-left" aria-hidden="true"></i>
              </span>{" "}
              Inkast från vår vardag
            </h2>
          </div>
        </div>
      );
    }
    return (
      <div className="loader-wrapper">
        <div className="loader"></div>
      </div>
    );
  }
}

export default WeWorkAtNews;
