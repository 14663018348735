import React, { Component } from "react";
import WorkAreas from "./WorkAreas";

class WeWorkLanding extends Component {
  state = {};
  render() {
    return (
      <div className="we-work-with-wrapper">
        <h2>Vad vi kan</h2>
        <WorkAreas weKnowActive={this.props.weKnowActive} />
      </div>
    );
  }
}

export default WeWorkLanding;
