import React, { Component } from "react";
import { getServerIp } from "../../services/proxy";

class ContactCardGbg extends Component {
  render() {
    const { card } = this.props;
    if (card.acf.office_contact !== false) {
      return (
        <React.Fragment>
          <div className="contact-card-wrapper">
            <img
              className="contact-icon-image"
              src={`${getServerIp()}` + card.acf.icon.url}
              alt={card.acf.icon.alt}
            />
            <div className="contact-card-text-content-wrapper">
              <p className="contact-card-name">{card.acf.name}</p>
              <p className="contact-card-title">
                {card.acf.office_contact_title}
              </p>
              <p className="contact-card-email">
                <span className="fa-icon">
                  <i className="fa fa-envelope-o" aria-hidden="true"></i>
                </span>{" "}
                <a href={"mailto:" + card.acf.email}>{card.acf.email}</a>
              </p>
              <p className="contact-card-phone">
                <span className="fa-icon">
                  <i className="fa fa-phone" aria-hidden="true"></i>
                </span>{" "}
                <a href={"tel:" + card.acf.phone}>+{card.acf.phone}</a>
              </p>
            </div>
          </div>
        </React.Fragment>
      );
    }
    return <React.Fragment />;
  }
}

export default ContactCardGbg;
