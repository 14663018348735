import React, { Component } from "react";
import axios from "axios";
import { getServerIp } from "../../services/proxy";

class WeWorkAtPresentation extends Component {
  state = { presentation: [], isLoaded: false };

  componentDidMount() {
    axios
      .get(`${getServerIp()}/wp-json/wp/v2/dragon_blog/`)
      .then(res => this.setState({ presentation: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  render() {
    const { presentation, isLoaded } = this.state;
    if (isLoaded) {
      return (
        <React.Fragment>
          {presentation.map(presentation => (
            <div className="presentation-wrapper fade-in" key={presentation.id}>
              <p
                className="presentation-paragraph"
                dangerouslySetInnerHTML={{
                  __html: presentation.acf.presentation_text
                }}
              ></p>
            </div>
          ))}
        </React.Fragment>
      );
    }
    return <div></div>;
  }
}

export default WeWorkAtPresentation;
