import React, {Component} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.css";
import "./App.css";
import {BrowserRouter as Router, Route} from "react-router-dom";
import Switch from "react-router-dom/es/Switch";

import LandingPage from "./components/pages/LandingPage";
import NewsPage from "./components/pages/WeWorkAtPage";
import AppNavbar from "./components/sections/AppNavbar";
import AppFooter from "./components/sections/AppFooter";
import NewsItemPage from "./components/pages/WeWorkAtPostPage";

import WeWorkWithPage from "./components/pages/WeWorkWithPage";
import BackToTop from "react-back-to-top-button";
import ScrollToTop from "react-router-scroll-top";
import AboutUsPage from "./components/pages/AboutUsPage";
import ContactPage from "./components/pages/ContactPage";
import CookieConsent from "react-cookie-consent";
import {getServerIp} from "./services/proxy";

class App extends Component {
    state = {
        navLinks: [
            {id: 1, name: "home", isActive: false},
            {id: 2, name: "we know", isActive: false},
            {id: 3, name: "dragon blog", isActive: false},
            {id: 4, name: "about us", isActive: false},
            {id: 5, name: "contact us", isActive: false}
        ]
    };

    setHomeActive = () => {
        this.setState({
            navLinks: [
                {id: 1, name: "home", isActive: true},
                {id: 2, name: "we know", isActive: false},
                {id: 3, name: "dragon blog", isActive: false},
                {id: 4, name: "about us", isActive: false},
                {id: 5, name: "contact us", isActive: false}
            ]
        });
    };

    setWeKnowActive = () => {
        this.setState({
            navLinks: [
                {id: 1, name: "home", isActive: false},
                {id: 2, name: "we know", isActive: true},
                {id: 3, name: "dragon blog", isActive: false},
                {id: 4, name: "about us", isActive: false},
                {id: 5, name: "contact us", isActive: false}
            ]
        });
    };

    setDragonBlogActive = () => {
        this.setState({
            navLinks: [
                {id: 1, name: "home", isActive: false},
                {id: 2, name: "we know", isActive: false},
                {id: 3, name: "dragon blog", isActive: true},
                {id: 4, name: "about us", isActive: false},
                {id: 5, name: "contact us", isActive: false}
            ]
        });
    };

    setAboutUsActive = () => {
        this.setState({
            navLinks: [
                {id: 1, name: "home", isActive: false},
                {id: 2, name: "we know", isActive: false},
                {id: 3, name: "dragon blog", isActive: false},
                {id: 4, name: "about us", isActive: true},
                {id: 5, name: "contact us", isActive: false}
            ]
        });
    };

    setContactUsActive = () => {
        this.setState({
            navLinks: [
                {id: 1, name: "home", isActive: false},
                {id: 2, name: "we know", isActive: false},
                {id: 3, name: "dragon blog", isActive: false},
                {id: 4, name: "about us", isActive: false},
                {id: 5, name: "contact us", isActive: true}
            ]
        });
    };

    render() {
        return (
            <div className="App">
                <Router>
                    <Switch>
                        <ScrollToTop>
                            <AppNavbar
                                homeActive={this.setHomeActive}
                                weKnowActive={this.setWeKnowActive}
                                dragonBlogActive={this.setDragonBlogActive}
                                aboutUsActive={this.setAboutUsActive}
                                contactUsActive={this.setContactUsActive}
                                getState={this.state.navLinks}
                            />
                            <div className="component-container">
                                <Route
                                    exact
                                    path={["/", "/index.html"]}
                                    render={props => (
                                        <LandingPage
                                            {...props}
                                            weKnowActive={this.setWeKnowActive}
                                            setHomeActive={this.setHomeActive}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/news"
                                    render={props => (
                                        <NewsPage
                                            {...props}
                                            setDragonBlogActive={this.setDragonBlogActive}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/news/newsItem:id"
                                    render={props => (
                                        <NewsItemPage
                                            {...props}
                                            setDragonBlogActive={this.setDragonBlogActive}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/work-with"
                                    render={props => (
                                        <WeWorkWithPage
                                            {...props}
                                            setWeKnowActive={this.setWeKnowActive}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/about"
                                    render={props => (
                                        <AboutUsPage
                                            {...props}
                                            setAboutUsActive={this.setAboutUsActive}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/contact"
                                    render={props => (
                                        <ContactPage
                                            {...props}
                                            setContactUsActive={this.setContactUsActive}
                                        />
                                    )}
                                />
                                <Route
                                    path="/wp-admin"
                                    render={props => (
                                        window.location = `${getServerIp()}/wp-admin}`
                                    )}
                                />
                            </div>
                            <BackToTop
                                showOnScrollDown
                                showAt={200}
                                speed={1500}
                                easing="easeInOutQuint"
                            >
                                <span className="scroll-to-top">▲</span>
                            </BackToTop>
                            <AppFooter/>
                            <CookieConsent
                                location="bottom"
                                buttonText="Acceptera cookies"
                                cookieName="drakryggenCookie"
                                style={{background: "#0C1C27", padding: "0em 1.5em 0em 1.5em"}}
                                buttonStyle={{
                                    color: "#fff",
                                    fontSize: "1em",
                                    backgroundColor: "#E50000",
                                    padding: ".5em 2em .7em 2em",
                                    borderRadius: "5px"
                                }}
                                expires={150}
                            >
                                Siten använder cookies för att förbättra din upplevelse{" "}
                                <span style={{fontSize: "10px"}}></span>
                            </CookieConsent>
                        </ScrollToTop>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;
