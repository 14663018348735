import React, { Component } from "react";

class AppFooter extends Component {
  state = {};
  render() {
    return (
      <div className="footer">
        <p>© 2020 Drakryggen AB | Utvecklad av Drakryggen</p>
      </div>
    );
  }
}

export default AppFooter;
