import React, { Component } from "react";
import AnimatedShowMore from "react-animated-show-more";
import { getServerIp } from "../../services/proxy";

class WeWorkWithItem extends Component {
  render() {
    const { workItem } = this.props;
    return (
      <div className="work-item-container">
        <div className="work-item-wrapper">
          <img
            className="work-icon-image"
            src={`${getServerIp()}` + workItem.acf.work_icon.url}
            alt={workItem.acf.work_icon.alt}
          />
          <p className="work-label">{workItem.acf.work_label}</p>
        </div>
        <AnimatedShowMore
          height={100}
          toggle={({ isOpen }) => (isOpen ? "▲" : "▼")}
          speed={1000}
          shadowColor="#fafafa"
          toggleColor="#fafafa"
        >
          <p
            className="work-area-text"
            dangerouslySetInnerHTML={{ __html: workItem.acf.text }}
          ></p>
        </AnimatedShowMore>
      </div>
    );
  }
}

export default WeWorkWithItem;
