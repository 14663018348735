import React, { Component } from "react";
import axios from "axios";
import { getServerIp } from "../../services/proxy";

class PageSectionFindUs extends Component {
  state = { data: [], isLoaded: false };

  componentDidMount() {
    axios
      .get(`${getServerIp()}/wp-json/wp/v2/find_us/`)
      .then(res => this.setState({ data: res.data, isLoaded: true }))
      .catch(err => console.log(err));
  }

  render() {
    const { data, isLoaded } = this.state;
    if (isLoaded) {
      return (
        <div className="page-sect-find-wrapper">
          <div className="find-us-content">
            {data.map(data => (
              <React.Fragment key={data.id}>
                <h2>{data.acf.title}</h2>
                <img
                  className="map-image"
                  src={`${getServerIp()}` + data.acf.image.url}
                  alt={data.acf.image.alt}
                />
              </React.Fragment>
            ))}
            <div className="map-links-wrapper">
              <a
                className="click-here-button"
                href="https://goo.gl/maps/T3YrKTHdW4m9a7jv8"
                target="_blank"
                rel="noopener noreferrer"
              >
                Till Göteborgskontoret{" "}
                <i className="fa fa-map-marker pl-2" aria-hidden="true"></i>
              </a>
              <a
                className="click-here-button"
                href="https://www.google.se/maps/place/Teknikringen+1E,+583+30+Link%C3%B6ping/@58.3935705,15.5649627,17z/data=!3m1!4b1!4m5!3m4!1s0x46596f7a14ca0093:0x1902d9c6750dd2c4!8m2!3d58.3935705!4d15.5671514"
                target="_blank"
                rel="noopener noreferrer"
              >
                Till Linköpingskontoret{" "}
                <i className="fa fa-map-marker pl-2" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      );
    }
    return <div className="loader justified-loader"></div>;
  }
}

export default PageSectionFindUs;
